<template>
  <div>
    <topic
      @like="like($event, index)"
      @favorite="favorite($event, index)"
      v-for="(item, index) in topicData"
      :key="item.id"
      :data="item"
      class="topicItem"
      @userInfoClick="handleToUserDetail(item)"
      @share="handleShare"
      @click="handleTopicClick"
      @originalClick="handleTopicClick"
      @follow="handleFollow(item, index)"
      @comment="handleComment"
      @handleNewActive="handleNewActive"
    />
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
    <div v-if="isload && !topicData.length" class="cardItem_main">
      <div class="hotCircle_noData">
        <div class="noData_box">
          <div class="noData_img"></div>
          <span>{{ noDataInfo.title1 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topic from '@/components/topicItem'
import commentToolDialog from '@/components/commentToolDialog'
import { myThumbsup } from '@/api/thumbsup'
import { addThumbsup, removeThumbsup } from '@/api/thumbsup'
import { addFavorites, removeFavorites } from '@/api/favorites'
import { addComment } from '@/api/comment'
import { attAdd, followRemove } from '@/api/att'
const ENTITY_TYPE_ARTICLE = 2
export default {
  name: 'cardItem', // 卡片
  props: {
    topicData: {
      type: Array,
      default: () => {}
    },
    isload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noDataInfo: {
        title1: '暂未相关帖子'
      },
      pageNum: 1,
      pageSize: 20,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      },
      showDialog: false,
      isAnonymousComment: false,
      commentContent: '',
      isLoad: false
    }
  },
  components: {
    topic,
    commentToolDialog
  },
  methods: {
    loadMore() {
      this.loadList()
    },
    async handleFollow(data) {
      const { id, myFollow } = data
      if (myFollow) {
        await followRemove(id)
        data.commonFollowCount--
      } else {
        await attAdd(id)
        data.commonFollowCount++
      }
      data.myFollow = !myFollow
    },
    loadList() {
      let listQueryParams = this.listQueryParams
      listQueryParams.userId = this.$store.getters.user.user_id
      if (listQueryParams.hasNextPage) {
        myThumbsup(this.listQueryParams)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages
            listQueryParams.pageNum = listQueryParams.pageNum + 1
            let resData = this.topicData
            res.records.forEach((item) => {
              let count = resData.filter(
                (innerItem) => innerItem.entityId === item.entityId
              )
              if (count.length === 0) {
                resData.push(item.entityDetail)
              }
            })
            this.topicData = resData
            console.log(this.topicData.length)
            this.isLoad = true
          })
          .catch(() => {
            this.isLoad = true
          })
      }
    },
    like(res, index) {
      console.log(res)
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          let count = data.articleStatisticVO.thumbsUpCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isThumbsUp = !res.isThumbsUp
          // if (!data.articleStatisticVO) {
          //   data.articleStatisticVO = {};
          // }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1
        })
      }
    },

    favorite(res, index) {
      console.log(res)
      // 取消收藏
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          let count = data.articleStatisticVO.favoritesCount - 1
          if (count < 0) count = 0
          data.articleStatisticVO.favoritesCount = count
        })
      } else {
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: '1'
        }).then(() => {
          let data = this.topicData[index]
          this.topicData[index].isFavorites = !res.isFavorites
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {}
          }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1
        })
      }
    },
    handleNewActive(item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      console.log(this.topicData[index])
      this.topicData[index].articleVoteVO = item
    },
    handleSwiperClick(data) {
      const type = data.type
      if (type === 0 || type === 1) {
        this.$router.push({
          name: 'topicDetail',
          query: { id: data.articleId }
        })
      } else if (type === 2) {
        this.$router.push({ name: 'forwardToWechat', query: { id: data.id } })
      } else if (type === 3) {
        window.location.href = data.content
      }
    },
    handleTopicClick({ articleId } = {}) {
      // if(status === 0) {
      this.$router.push({ name: 'topicDetail', query: { id: articleId } })
      // }
    },
    /**
     * 分享事件
     */
    handleShare({ id: quoteId } = {}) {
      this.$router.push({ name: 'createTopic', query: { quoteId } })
    },
    handleToUserDetail({ userId } = {}) {
      this.$router.push({ name: 'userInfoDetail', params: { userId } })
    },
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
      console.log(this.commentParams)
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: { articleId: entityId },
        isAnonymousComment: isAnonymous
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({ message: '评论内容不能为空', type: 'danger' })
        return
      }
      await addComment({ content, entityId, entityType, isAnonymous })
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const { articleStatisticVO } = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p,
h3,
ul,
li {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  text-decoration: none;
  list-style: none;
}
.cardItem_main {
  .cardItem_lis1 {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 16.8px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;

      .img_Box {
        width: 44px;
        height: 44px;
        margin-right: 6px;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }
      .header_info {
        h3 {
          font-size: 14px;
          color: #000000;
        }
        p {
          font-size: 12px;
          color: #6d7278;
          line-height: 25px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url('~@/assets/imgs/circle/Slice41@2x.png');
        background-size: 100%;
      }
    }
  }
  .cardItem_List {
    margin-top: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f4f4f4;
    .tag_box {
      font-size: 13px;
      text-align: justify;
      color: #fe5800;
      margin-top: 14px;
      margin-bottom: 5px;
      span {
        display: inline-block;
        margin-right: 5px;
      }
    }
    .title {
      font-size: 16px;
      color: #000000;
    }
    .message {
      font-size: 13px;
      color: #333333;
      line-height: 20px;
    }
    .pickture_box {
      width: 100%;
      span {
        display: inline-block;
      }
      img {
        width: 110px;
        height: 110px;
        margin-right: 3.5px;
      }
      img:last-child {
        margin-right: 0;
      }
    }
    .icon_box {
      display: flex;
      justify-content: space-between;
      margin-top: 10.2px;
      .icon_left {
        display: flex;
        width: 66%;
        justify-content: space-between;
      }
      .icon_right {
        width: 44px;
        height: 16px;
        display: inline-block;
        background: #f6f6f6;
        border-radius: 8px;
        color: #fa6400;
        .van_icon {
          position: relative;
          left: 4px;
          top: 1px;
        }
        .havad {
          position: relative;
          top: -2px;
        }
      }
      .icon1_item {
        font-size: 12px;
        color: #6d7278;
        .tils {
          position: relative;
          left: 4px;
          top: -4px;
        }
        .icon_pic1 {
          // 消息
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52(7).svg');
          background-size: 16px 16px;
        }
        .icon_pic1_active {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52_a.png');
          background-size: cover;
        }

        .icon_pic2 {
          // 收藏
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52.svg');
          background-size: cover;
        }
        .icon_pic2_active {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52(4).png');
          background-size: cover;
        }
        .icon_pic3 {
          // 转发
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52(1).svg');
          background-size: cover;
        }
        .icon_pic3_active {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52(6).png');
          background-size: cover;
        }

        .icon_pic4 {
          // 点赞
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice522.svg');
          background-size: cover;
        }
        .icon_pic4_active {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice52(4).png');
          background-size: cover;
        }
      }
    }
  }
  .hotCircle_noData {
    display: flex;
    height: 380px;
    justify-content: center;
    align-items: center;
    text-align: center;
    .noData_box {
      .noData_img {
        width: 212px;
        height: 114px;

        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;
        img {
          width: 20px;
          height: 20px;
        }
      }
      span {
        font-size: 14px;
      }
    }
    .noData_qjr {
      font-size: 14px;
      color: #fe5800;
      margin-top: 7px;
    }
    span {
      font-size: 12px;
      color: #6d7278;
    }
  }
  .vote_main {
    height: 277px;
    background: #f6f6f6;
    border-radius: 6px;
    .vote_titl {
      margin-left: 12px;
      .titl_span01 {
        padding-top: 15px;
        padding-bottom: 8px;
        font-size: 16px;
        display: inline-block;
        text-align: center;
        color: #000000;
        line-height: 18px;
      }
      .titl_span02 {
        width: 30px;
        height: 16px;
        display: inline-block;
        background: rgba(254, 88, 0, 0.1);
        border-radius: 1px;
        font-size: 10px;
        text-align: center;
        color: #fe5800;
        line-height: 15px;
      }
    }
    .vote_titl2 {
      font-size: 12px;
      margin: 0 12px;
      padding-bottom: 11.5px;
      border-bottom: 1px solid #e0e0e0;
      color: #6d7278;
      line-height: 18px;
    }
    .vote_titl3 {
      height: 40px;
      margin: 11.5px 12px 8px;
      background: #ffffff;
      color: #5475a6;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      text-indent: 11px;
      line-height: 35px;
    }
    .vote_titl4 {
      padding-left: 12px;
      .titl4_tp {
        margin-right: 10px;
        width: 64px;
        height: 32px;
        display: inline-block;
        background: #fe5800;
        border-radius: 99px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        line-height: 28px;
      }
      .title4_ri {
        font-size: 12px;
        color: #6d7278;
        line-height: 18px;
      }
    }
  }
  .vote_tbqz {
    margin-top: 14px;
    .tpqz_ts {
      background: #f6f6f6;
      border-radius: 44px;
      padding: 3px 4px;
      font-size: 12px;
      text-align: center;
      color: #fa6400;
      line-height: 20px;
      margin-right: 10px;
    }
    .tpqz_tbzgc {
      font-size: 12px;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>
