<template>
  <div class="circleTz">
    <pageHead title="帖子详情"></pageHead>

    <div class="scroll">
      <div class="circleTz_box">
        <cardItem @rightIcon="rightIcon" :allTzLilst="allTzLilst"></cardItem>
      </div>
      <div class="bar_c1">
        <div class="bar_02">
            <div class="bar_02_1" v-for="item in tabList" :key="item.type"  >
              <div :class="currentIndex === item.type ? 'active' : 'active_no'" @click="tabBar_click(item.type)">{{item.title}}</div>
            </div>
        </div>
        <template v-if="currentIndex === 0">
           <messageItem
          :key-map="keyMap"
                    :showNumber="2"
                    :comment-datas="commentDatas"
                    :orderBy="'create_time'"
                    @submitComment="submitComment"
          ></messageItem>
        </template>
        <template v-else>
          <commentItem :ContSet="ContSet" @joinBtn="joinBtn" @hfClick="hfClick" :hot_list="hot_list" :noDataInfo="noDataInfo"></commentItem>
        </template>

        <commentToolDialog :show-dialog.sync="showDialog" :isAnonymousComment.sync="isAnonymousComment"
          v-model="commentContent" hide-relation hide-img
          @publish="handlePublishComment"/>

        <!-- 弹出投票详情 -->
          <van-action-sheet v-model="rigthShow"  cancel-text="取消" close-on-click-action :actions="actions" @select="onSelect" />
      </div>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import cardItem from './common/cardItem.vue'
import commentItem from "./common/commentItem"
import messageItem from "./common/message-item"
import commentToolDialog from '@/components/commentToolDialog'

const SHARE = {label: "转发", key: 'share', valueKey: 'forwardCount'}
const COMMENT = {label: "评论", key: 'comment', valueKey: 'commentCount'}
const LIKE = {label: "赞", key: 'like', valueKey: 'thumbsUpCount'}
  export default {
    name: 'circleTz', // 帖子详情
    components: {
      pageHead,
      cardItem,
      commentItem,
      messageItem,
      commentToolDialog
    },
     inject:{
    level:{
      default:1
    }
  },
  provide(){
    return {
      level:this.level+1
    }
  },
    data() {
      return {
        renderLevel:1,
        rigthShow: false,
        id:0,
        plActive:0,
        allTzLilst: [
          {
            id:1,
            headerUrl: 'https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg',
            name: '吉大利',
            date: '04/25 09:25',
            tag:['#每日学习#','#今日xx#'],
            title: '我也想买极氪',
            message: '极氪车太漂亮了，可惜预售没订到，什么时候内部员工可以买啊，我想买一辆，就是不知道有没有优惠。默默时刻关注着！',
            pickture: [
              {
                url: 'https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'
              },
              {
                url: 'https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'
              },
              {
                url: 'https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'
              },
            ],
            voteShow: true,
            messageNum: 4,
            goodNum: 12,
            collectNum: 4,
            share: 3,
            haveSeen: 122,
            vote: {
              title: '关于去哪儿吃晚饭的投票',
              tag: 0,
              maxlen: 3,
              lastDate: '2022-4-12',
              address: ['选滨江天街', '城西银泰', '西溪天街'],
              numPelp: 33,
              lastDateNum: 5,
              tbgc: true
            }
          },
        ],
        currentIndex: 0,
        ContSet: {
          title_if: false,
        },
        tabList: [
          {
            title: '评论(20)',
            type: 0,
          },
          {
            title: '赞(10)',
            type: 1,
          }
        ],
        hot_list:[
          {
          id: 1,
           picture: 'xx/xx1.png',
            title: '王晓利',
            joinNum: 3,
            tzNum: 2223,
            jkinfo: ' ',
            btnRiType: 3, // 点赞
            isGood: '', // 好的
            isMaster: false, // 是否圈主
            goodNum: 2,
            type: 0, // 0 加入 1 未加入 2 申请 3 已申请 4 我创建的 5 进圈子
          },
        ],
        noDataInfo: {
          show: false,
          title1: '暂无数据'
        },
        showDialog: false,
        isAnonymousComment: false,
        commentContent: "",
        actions:[{ name: '导出投票详情' }, { name: '删除帖子' }],
        keyMap: {
                pid: "parent_comment_id",
                id: "id",
                isAdmin: "admin_comment"
        },
        commentDatas: [
            {
                id: 1,
                admin_comment: 0,
                avatar:
                    "https://img2.baidu.com/it/u=134769530,4268043097&fm=253&fmt=auto&app=138&f=JPEG?w=533&h=333",
                create_time: "2020-05-31 23:03:55",
                email: "",
                nickname: "张明明1",
                parent_comment_id: null,
                content: "我是评论1",
                goodNum: 23,
                // eslint-disable-next-line no-dupe-keys
                admin_comment: 0
            }
        ],
        }
    },
    created() {
      this.id = this.$route.query.id
    },
    methods: {
      // 右侧点击
      rightIcon() {
          this.rigthShow = true
      },
        interactionListData() {
      const {interactionType, commentList, thumbsupList, shareList} = this
      switch (interactionType) {
        case SHARE.key:
          return shareList || []
        case COMMENT.key:
          return commentList || []
        case LIKE.key:
          return thumbsupList || []
        default:
          return []
      }
    },
    // 栏目切换点击
    tabBar_click(type) {
      this.currentIndex = type
    },
    joinBtn() {

    },
    // 回复点击
    hfClick(item) {
      console.log(item)
      this.showDialog = true
    },
    /**
     * 发布评论
     */
    async handlePublishComment() {
      console.log(this.commentContent)
      this.showDialog = false

    },
    // 数据提交
    submitComment(item) {
            this.commentDatas.push({
                id: this.commentDatas.length + 1,
                admin_comment: 0,
                avatar: "https://img0.baidu.com/it/u=3966731730,957573008&fm=253&fmt=auto&app=138&f=JPEG?w=281&h=500",
                content: item.content,
                create_time: "1分钟前",
                email: "",
                goodNum: 1,
                nickname: "名字" + (this.commentDatas.length + 1),
                parent_comment_id: item.pid
            });
    },
    // 弹出窗回调
    onSelect(v) {
      console.log(v.name)
      this.rigthShow = false
    }
    }
  }
</script>

<style lang="scss" scoped>
.circleTz {
  .scroll {
    height: 632px;
    overflow: scroll;
  }
  .circleTz_box {
    padding:0 22px;
    background: #fff;
    overflow: hidden;
  }
  .bar_c1 {
    margin-top: 5px;
    background:#fff;
    padding:0 22px;
    .bar_02 {
      font-size: 14px;
      line-height: 16px;
      .bar_02_1 {
        display: inline-block;
        margin: 15px 32px 15px 0;
      }
      .bar_02_1:last-child {
        margin-right: 0;
      }
    }
  }
  .active {
    color: #000;
    position: relative;
    &::before{
        width: 2px;
        height: 4px;
        content: '——';
        position: absolute;
        color: #FE5800;
        left: 6px;
        top:12px;
    }
  }
  .active_no {
    color: #6D7278;
  }

}
</style>
