
<template>
    <div class="j-comment" @click="showVEmojiPicker = false">

        <div
            class="j-comment-item bottomline"
            v-for="(item, index) in showComentDatas"
            :key="'comment' + index"
        >
            <div class="j-comment-header">
                <div class="j-comment-header-pre">
                  <div><img class="j-comment-header-img" :src="item.avatar" /></div>
                  <div class="j-comment-header-ri ri">
                    <div class="j-comment-header-nickname"
                        >{{ item.nickname }}<span
                            v-if="item[keyMap.isAdmin] === 1"
                            style="color:skyblue;"
                            >(作者)</span
                        ></div>
                    <div class="j-comment-body" @click="replyClick(item, index)"> {{ entitiestoUtf16(item.content) }}</div>
                    <div class="j-comment-header-time">
                        {{ item.create_time }}
                      <span class="huifu" @click="replyClick(item, index)">{{ item.showReply ? "取消回复" : "回复" }}</span>
                        </div>
                  </div>
                </div>

                <!-- 点赞 -->
                    <div class="rightDz_box">
                    <div>
                        <div class="rightDz"> </div>
                        <div class="num">{{item.goodNum}}</div>
                    </div>
                    </div>
            </div>

                <commentToolDialog :show-dialog.sync="item.showReply"
                       v-model="replyText" hide-relation hide-img
                       @publish="submitReply(item.id)"
                       @confirm='submitReply(item.id)'/>

            <div class="j-comment-childer" v-if="item.children && item.children.length > 0">
                <div
                    class="j-comment-item"
                    v-for="(children, childrenIndex) in item.showChildren"
                    :key="'children' + childrenIndex" >
                    <div class="j-comment-header">
                        <div class="j-comment-header-pre pre2">
                          <div><img
                                class="j-comment-header-img2"
                                :src="children.avatar"
                            /></div>
                          <div class="j-comment-header-ri">
                            <div class="j-comment-header-nickname">
                                <div>{{ children.nickname }}<span
                                    v-if="children[keyMap.isAdmin] === 1"
                                    style="color:skyblue;"
                                    >(作者)</span>
                                    <span
                                        class="j-comment-header-reply-nickname"
                                        v-if="children.reply" > <span class="col1">回复</span><span class="col2">{{ children.reply }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="j-comment-body" @click="replyClick(
                                    item,
                                    index,
                                    children,
                                    childrenIndex,
                                    true) " > {{ entitiestoUtf16(children.content) }} </div>
                          </div>
                        </div>
                         <!-- 点赞 -->
                        <div class="rightDz_box">
                            <div>
                                <div class="rightDz"> </div>
                                <div class="num">{{children.goodNum}}</div>
                            </div>
                        </div>
                    </div>
                     <commentToolDialog :show-dialog.sync="children.showReply"
                       v-model="replyText" hide-relation hide-img
                       :id="'reply-' + index + '-' + childrenIndex"
                       @publish="submitReply(children.id)"
                       @confirm='submitReply(children.id)'/>
                </div>
                <div
                    v-if="item.showChildren.length < item.children.length"
                    :key="'more' + index"
                    class="j-comment-childer-more"
                    @click="showMore(item, index)"
                >
                    查看更多回复>
                </div>
                <div
                    v-else-if="item.showChildren.length > showNumber"
                    :key="'more' + index"
                    class="j-comment-childer-more"
                    @click="showMore(item, index)"
                >
                    收起回复>
                </div>
            </div>
        </div>

          <div class="j-comment-bottom">

                <div class="j-comment-content" @click.prevent="commentInput">说点什么吧！~</div>
                <div class="icon_box">
                    <div class="icon_left">
                        <p class="icon1_item">
                            <span class="icon_pic1"></span><span class="tils">{{messageNum}}</span>
                        </p>
                        <p class="icon1_item">
                            <span class="icon_pic4"></span><span class="tils">{{messageNum}}</span>
                        </p>
                        <p class="icon1_item">
                            <span class="icon_pic2"></span><span class="tils">{{messageNum}}</span>
                        </p>
                    </div>
                </div>
            </div>

            <commentToolDialog :show-dialog.sync="commentShowReply"
                       v-model="commentText" hide-relation hide-img
                       @publish="submitComment()"
                       @confirm='submitComment()'/>

    </div>
</template>

<script>
import commentToolDialog from '@/components/commentToolDialog'
export default {
    name: "JComment",
    props: {
        title: {
            type: String,
            default: "热门评论"
        },
        orderBy: {
            type: String,
            default: "time"
        },
        rank: {
            type: String,
            default: "desc"
        },
        showNumber: {
            type: Number,
            default: 2
        },
        withCommentContent: {
            type: Boolean,
            default: true
        },
        keyMap: {
            type: Object,
            default: () => {
                return {
                    pid: "pid",
                    id: "id",
                    isAdmin: "isAdmin"
                };
            }
        },
        commentDatas: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    components: {
        commentToolDialog
    },
    data() {
        return {
            showComentDatas: [],
            replyText: "",
            commentText: "",
            lastLength: 0,
            showItem: [],
            showVEmojiPicker: false,
            commentShowReply: false,
            emojiTextId: "",
            messageNum: 1
        };
    },
    created() {
        this.initData();
    },
    watch: {
        commentDatas() {
            this.initData();
        }
    },
    computed: {},
    methods: {
        // 表情转码
        utf16toEntities(str) {
            const patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
            str = str.replace(patt, char => {
                let H;
                let L;
                let code;
                let s;
                if (char.length === 2) {
                    H = char.charCodeAt(0); // 取出高位
                    L = char.charCodeAt(1); // 取出低位
                    code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // 转换算法
                    s = `&#${code};`;
                } else {
                    s = char;
                }
                return s;
            });
            return str;
        },
        // 表情解码
        entitiestoUtf16(strObj) {
            const patt = /&#\d+;/g;
            const arr = strObj.match(patt) || [];
            let H;
            let L;
            let code;
            for (let i = 0; i < arr.length; i += 1) {
                code = arr[i];
                code = code.replace("&#", "").replace(";", "");
                // 高位
                H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
                // 低位
                L = ((code - 0x10000) % 0x400) + 0xdc00;
                code = `&#${code};`;
                const s = String.fromCharCode(H, L);
                strObj = strObj.replace(code, s);
            }
            return strObj;
        },
        showEmoji(el) {
            let v = document.getElementById("v-emoji-picker");
            v.style.left = el.pageX + 5 + "px";
            v.style.top = el.pageY + 5 + "px";
            this.showVEmojiPicker = !this.showVEmojiPicker;
            this.emojiTextId = el.target.id;
        },
        selectEmoji(emoji) {
            // 选择emoji后调用的函数
            let input = null;
            if (this.emojiTextId === "comment-emoji") {
                input = document.getElementById("j-comment-content");
            } else {
                let id = this.emojiTextId.split("emoji-");
                input = document.getElementById(id[1]);
            }
            let startPos = input.selectionStart;
            let endPos = input.selectionEnd;
            let resultText =
                input.value.substring(0, startPos) +
                emoji.data +
                input.value.substring(endPos);
            input.value = resultText;
            input.focus();
            input.selectionStart = startPos + emoji.data.length;
            input.selectionEnd = startPos + emoji.data.length;
            if (this.emojiTextId === "comment-emoji")
                this.commentText = resultText;
            else {
                this.replyText = resultText;
            }
        },
        submitReply(id) {
            const params = {
                pid: id,
                content: this.utf16toEntities(this.replyText)
            };
            this.replyText = "";
            this.showVEmojiPicker = false;
            this.$emit("submitComment", params);
        },
        submitComment(id = null) {
            this.commentShowReply = false
            const params = {
                pid: id,
                content: this.utf16toEntities(this.commentText)
            };
            this.commentText = "";
            this.showVEmojiPicker = false;
            this.$emit("submitComment", params);
        },
        showMore(item, index) {
            this.showItem[index] = !this.showItem[index];
            if (item.showChildren.length < item.children.length) {
                item.showChildren = item.children;
            } else {
                item.showChildren = item.children.slice(0, this.showNumber);
            }
            this.$set(this.showComentDatas, index, item);
        },
        replyClick(
            item,
            index,
            children = {},
            childrenIndex = 0,
            isChildren = false
        ) {
            const flag = isChildren ? !children.showReply : !item.showReply;
            this.showVEmojiPicker = false;
            this.hideAll();
            let ref = "reply-" + index;
            if (isChildren) {
                children.showReply = flag;
                if (children.showReply)
                    ref = "reply-" + index + "-" + childrenIndex;
            } else {
                item.showReply = flag;
            }
            this.$set(this.showComentDatas, index, item);
            if (children.showReply || item.showReply) {
                this.$nextTick(() => {
                    document.getElementById(ref).focus();
                });
            } else {
                this.replyText = "";
            }
        },
        hideAll() {
            this.replyText = "";
            this.showComentDatas.map(item => {
                item.showReply = false;
                item.showChildren &&
                    item.showChildren.map(item1 => {
                        item1.showReply = false;
                    });
            });
            this.showComentDatas = [...this.showComentDatas];
        },
        commentInput() {
            this.commentShowReply = true
        },
        replyInput(item) {
            const currentLength = item.target.value.length;
            // 判断字数如果比之前少了，说明内容正在减少，需要清除高度样式，重新获取
            if (currentLength < this.lastLength) {
                item.target.style.height = "";
            }
            const currentHeight = item.target.scrollHeight;
            // 如果内容高度发生了变化，再去设置高度值
            if (currentHeight > item.target.offsetHeight) {
                item.target.style.height = currentHeight + "px";
            }
            this.lastLength = currentLength;
        },
        rankDatas(datas) {
            if (this.rank === "desc") {
                return datas.sort((b, a) => {
                    return (
                        new Date(a[this.orderBy]).getTime() -
                        new Date(b[this.orderBy]).getTime()
                    );
                });
            }
            return datas.sort((a, b) => {
                return (
                    new Date(a[this.orderBy]).getTime() -
                    new Date(b[this.orderBy]).getTime()
                );
            });
        },
        initData() {
            this.showComentDatas = this.getTreeData(
                this.commentDatas,
                this.keyMap.id,
                this.keyMap.pid
            );
            this.showComentDatas = this.rankDatas(this.showComentDatas);
            for (let i = 0; i < this.showComentDatas.length; i++) {
                if (this.showComentDatas[i].children) {
                    this.showComentDatas[i].children = this.treeToArr(
                        this.showComentDatas[i].children
                    );
                    this.showComentDatas[i].children = this.rankDatas(
                        this.showComentDatas[i].children
                    );
                    this.showComentDatas[i].showChildren = this.showComentDatas[
                        i
                    ].children.slice(
                        0,
                        this.showItem[i]
                            ? this.showComentDatas[i].children.length
                            : this.showNumber
                    );
                }
            }
        },
        treeToArr(data, pName = null, pContent = "") {
            let cloneData = JSON.parse(JSON.stringify(data));
            let res = [];
            cloneData.map(item => {
                if (item.children) {
                    res.unshift(
                        ...this.treeToArr(
                            item.children,
                            item.nickname,
                            item.content
                        )
                    );
                }
                item["reply"] = pName;
                item["pContent"] = pContent;
                res.unshift(item);
            });
            if (this.showItem.length === 0) {
                this.showItem = new Array(res.length).fill(false);
            }
            return res;
        },
        getTreeData(data, id = "id", pid = "pid") {
            let cloneData = JSON.parse(JSON.stringify(data));
            return cloneData.filter(parent => {
                let branchArr = cloneData.filter(
                    child => parent[id] == child[pid]
                );
                for (let i = 0; i < branchArr.length; i++) {
                    branchArr.parent_nickname = parent.nickname;
                }
                branchArr.length > 0 ? (parent["children"] = branchArr) : "";
                return parent[pid] === null;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.j-comment {
    padding-bottom: 64px;
    padding-top: 8px;
    .j-comment-bottom {
    width: 100%;
      height: 64px;
      background: #fff;
      position: absolute;
      bottom: 0;
      padding-top: 14px;
      display: flex;
      .j-comment-content {
        width: 133px;
        height: 36px;
        font-size: 14px;
        color: #6D7278;
        background: #f6f6f6;
        border-radius: 24px;
        border: none;
        text-indent: 16px;
        line-height: 35px;
      }
    }
    .j-comment-content-btns {
        display: flex;
        button {
            margin-left: auto;
            background-color: #1e80ff;
            border: none;
            color: white;
            border-radius: 8%;
            cursor: pointer;
        }
    }
    .j-comment-header-nickname {
        padding-left: 8px;
        font-size: 14px;
        color: #6d7278;
    }
    .bottomline:first-child{
        padding-top: 0;
    }
    .bottomline {
        padding-top: 8px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }
    .j-comment-item {
        .j-comment-header {
            display: flex;
            justify-content: space-between;
            .j-comment-header-pre {
              display: flex;
              height: 65px;
              .j-comment-header-ri {
                .j-comment-body {
                    font-size: 14px;
                    padding-left: 8px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    color: rgba(0,0,0,0.80);
                }
              }
              .j-comment-header-img {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  position: relative;
                  top:8px;
              }
              .j-comment-header-img2 {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  position: relative;
                  top: -5px;
              }
            }
            .pre2 {
                display: flex;
                align-items: center;
            }
            .j-comment-header-time {
                font-size: 12px;
                margin-left: 8px;
                color: #999999;
            }

        }
        .rightDz_box {
            display: flex;
            align-items: center;
            .rightDz {
            width: 14px;
            height: 14px;
                background: url('~@/assets/imgs/circle/Slice_322.svg') no-repeat;
                background: cover;
            }
            .num {
            font-size: 10px;
            color: #6d7278;
            line-height: 12px;
            text-align: center;
            }
        }
        .j-comment-reply-content {
             margin-left:20px;
            border: 1px solid;
            resize: both;
            overflow: hidden;
        }
        .j-comment-reply-btns {
            display: flex;
            button {
                background-color: #1e80ff;
                border: none;
                color: white;
                border-radius: 8%;
                cursor: pointer;
            }
        }
        .j-comment-childer {
            margin-left: 33px;
            .j-coment-children-content {
                overflow: hidden;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .j-comment-childer-more {
        cursor: pointer;
        font-size: 12px;
        color: #fe5800;
    }
    .j-comment-header-reply-nickname {
        font-size: 14px;
      line-height: 18px;
    }
    .col1{
        color: #000;
    }
    .col2{
        color: #999;
    }
    .huifu {
        font-size: 10px;
        color: #666666;
    }
    .icon_box {
      display: flex;
      justify-content: space-between;
      .icon_left {
        display: flex;
        width: 66%;
        justify-content: space-between;
      }
      .icon_right {
        width: 44px;
        height: 16px;
        display: inline-block;
        background: #f6f6f6;
        border-radius: 8px;
        color: #FA6400;
        .van_icon {
          position: relative;
          left: 4px;
          top:1px;
        }
        .havad {
          position: relative;
          top:-2px;
        }
      }
      .icon1_item:first-child {
          margin-left: 44px;
      }
      .icon1_item {
        font-size: 12px;
        color: #6d7278;
        margin-right: 31px;
        display: flex;
        .tils {
           padding-left: 7px;
        }
        .icon_pic1 { // 消息
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice52(7).svg');
            background-size: 16px 16px;
        }
        .icon_pic1_active {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice52_a.png');
            background-size: cover;
        }

        .icon_pic2 { // 收藏
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice52.svg');
            background-size: cover;
        }
        .icon_pic2_active {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice52(4).png');
            background-size: cover;
        }
        .icon_pic4 { // 点赞
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice522.svg');
            background-size: cover;
        }
        .icon_pic4_active {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice52(4).png');
            background-size: cover;
        }
      }
    }
}
</style>
