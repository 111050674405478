<template>
  <div class="commentItem">
    <header class="hotCircle_title" v-if="ContSet.title_if">
      <span class="hotCircle_join">热门圈子</span>
      <span class="hotCircle_circle" @click.stop="AllCl">全部圈子 ></span>
    </header>
    <main class="hotCircle_list_box" :style="{'height': height + 'px'}">
      <template v-if="hot_list.length > 0">
        <div class="comment_box">
          <div class="hot_Item" v-for="(item, index) in hot_list" :key="index" @click.prevent="hotClick(item)">
            <div class="left">
              <div class="pic_box">
                <img src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg" alt="">
              </div>
              <div class="message" :class="!item.jkinfo ? 'mes1':'mes2'">
                <span class="message_til">{{item.title}}</span>
                <div class="subTitle dhEllipsis">{{item.jkinfo}}</div>
                <div class="message_gfzs">{{item.joinNum}}分钟前&nbsp; <span @click.prevent="hfClick(item)">回复</span> </div>
              </div>
            </div>

            <!-- 点赞 -->
            <div class="rightDz_box">
              <div>
                <div class="rightDz"> </div>
                <div class="num">{{item.goodNum}}</div>
              </div>
            </div>
          </div>
          <!-- 回复的列表 -->
          <div class="huifu_list">

          </div>
        </div>
      </template>
      <!-- 无数据展示 -->
      <template v-else>
        <div class="hotCircle_noData" :style="{'height': nodataHi + 'px'}" v-if="noDataInfo.show == false ? true : false">
          <div class="noData_box" >
            <div class="noData_img"></div>
            <span>{{noDataInfo.title1 || '暂无数据！'}}</span>
          </div>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'commentItem',
    props: {
      height: {
        type: Number,
        default:255,
      },
      nodataHi: {
        type: Number,
        default: 144
      },
      ContSet: {
        type: Object,
        default: () => {}
      },
      hot_list: {
        type: Array,
        default: () => []
      },
      noDataInfo: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      // 全部
      AllCl() {
        this.$router.push({ name: "circleMy"});
        // 设置选中下标
        localStorage.setItem('type', 1)
      },
      // 申请/加入 右侧按钮
      joinBtn(type) {
        this.$emit('joinBtn', type)
      },
      // 我管理的圈子退出
      myGlCircle(item) {
        this.$emit('myGlCircle', item)
      },
      // 点击
      hotClick(item) {
        this.$emit('hotClick', item)
      },
      // 点击回复
      hfClick(item) {
        this.$emit('hfClick', item)
      }
    }
  }
</script>

<style lang="scss" scoped>
p{
  margin: 0;
}
.colf6f6 {
  background: #f6f6f6;
  color: #6d7278;
}
.colfe5 {
  background: #fe5800;
  color: #fff;
}
.col6D7278 {
  color: #6D7278;
}
.reas {
  position: relative;
  top:-4px;
}
.commentItem {
  background: #fff;
  .hotCircle_title {
      overflow: hidden;
      line-height: 20px;
      .hotCircle_join {
        font-size: 15px;
        float: left;
        color: #000000;
      }
      .hotCircle_circle{
        font-size: 12px;
        color: #6d7278;
        float: right;
      }
    }
  .hotCircle_list_box {
    margin-top: 20px;
    overflow: scroll;
    .hot_Item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 29px;
      .left {
        display: flex;
        .message_til {
          font-size: 14px;
          color: #000000;
        }
        .mes1 {
          position: relative;
          top: -5px;
        }
        .mes2 {
          position: relative;
          top: -9px;
        }
        .message {
          .subTitle {
            font-size: 12px;
            color: #6d7278;
            letter-spacing: 0;
          }
        }
        .message_gfzs {
          font-size: 12px;
          color: #6d7278;
        }
        .pic_box {
          width: 44px;
          height: 44px;
          margin-right: 6px;
          display: inline-block;
          border-radius: 50%;
          background: #f60;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .hotCircle_noData {
    display: flex;
    height: 380px;
    justify-content: center;
    align-items: center;
    text-align: center;
    .noData_box {
      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .noData_qjr {
      font-size: 14px;
      color: #fe5800;
      margin-top: 7px;
    }
    span {
      font-size: 12px;
      color: #6d7278;
    }
  }
  .rightGx {
      width: 24px;
      height: 24px;
      background: url('~@/assets/imgs/circle/Slice43_32.svg');
      background: cover;
  }
  .rightDz_box {
    display: flex;
    align-items: center;
    .rightDz {
      width: 14px;
      height: 14px;
        background: url('~@/assets/imgs/circle/Slice_322.svg') no-repeat;
        background: cover;
    }
    .num {
      font-size: 10px;
      color: #6d7278;
      line-height: 12px;
      text-indent: 3px;
    }
  }
}
</style>
